const LocalStorageLoginContextKey = 'tadpoleLoginContext'

type JWT = {
  sub: string
  exp: number
}

const parseToken = (encodedToken: string): JWT =>
  JSON.parse(atob(encodedToken.split('.')[1]))

export class LocalJWTManager {
  tokenName: string
  refreshTokenName: string
  encodedToken: string | null
  refreshToken: string | null
  parsedToken: JWT | null = null
  constructor(
    tokenName = 'tadpoleJWT',
    refreshTokenName = 'tadpoleRefreshJWT'
  ) {
    this.tokenName = tokenName
    this.refreshTokenName = refreshTokenName
    this.encodedToken = localStorage.getItem(tokenName)
    this.refreshToken = localStorage.getItem(refreshTokenName)
    if (this.encodedToken !== null) {
      this.parsedToken = parseToken(this.encodedToken)
    }
  }

  login(context = '/') {
    localStorage.setItem(LocalStorageLoginContextKey, context)
    if (this.parsedToken == null) {
      window.location.href = `/authorization/redirect`
    } else {
      this.encodedToken = localStorage.getItem(this.tokenName)
      if (this.encodedToken !== null) {
        this.parsedToken = parseToken(this.encodedToken)
      }
    }
  }

  isExpired() {
    if (this.parsedToken === null) {
      return true
    }
    return new Date() > new Date(this.parsedToken.exp * 1000)
  }

  logout() {
    localStorage.removeItem(this.tokenName)
    localStorage.removeItem(this.refreshTokenName)
    this.encodedToken = null
    this.refreshToken = null
    this.parsedToken = null
  }

  refresh() {
    if (this.refreshToken != null) {
      return fetch(`/authorization/refresh?refreshToken=${this.refreshToken}`)
        .then((response) => {
          if (response.status === 400) {
            this.logout()
            this.login(window.location.pathname)
          } else if (response.status !== 200) {
            throw Error('FATAL: Unable to authenticate!')
          } else {
            return response.json()
          }
        })
        .then((data) => {
          localStorage.setItem(this.tokenName, data.access_token)
          localStorage.setItem(this.refreshTokenName, data.refresh_token)
          this.encodedToken = data.access_token
          this.refreshToken = data.refresh_token
          if (this.encodedToken !== null) {
            this.parsedToken = parseToken(this.encodedToken)
          }
        })
        .catch((error) => {
          console.error('Error during authentication token refresh: ', error)
        })
    } else {
      this.login(window.location.pathname)
      return Promise.resolve()
    }
  }

  /**
   * The token's subject, which in practice, is the email address of the user
   */
  getUserName() {
    if (this.parsedToken !== null) {
      return this.parsedToken.sub
    } else {
      return 'unauthenticated user'
    }
  }
}
