import * as yup from 'yup'

// TODO: Add the rest of the fields
export const validationSchema = yup.object().shape({
    service_name: yup
        .string()
        .matches(/^[a-z\-0-9]+$/, 'Service name must be all lower case.')
        .required('This field is required'),
    repo_name: yup
        .string()
        .required('This field is required'),
    revision: yup
        .number()
        .required('This field is required')
})
