import * as React from 'react'
import {Formik, Form, useFormikContext} from 'formik'
import { useMutation } from 'react-query'

import {
  FocusView,
  FocusHeader,
  HeadingGroup,
  Title,
  FocusBody,
  FocusCloseButton,
  FocusClose,
  FocusActions,
  ScrollPanel
} from '@toasttab/buffet-pui-config-templates'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import {
  RepoNameField,
  ServiceNameField,
  RevisionField,
  DockerImageUriField
} from './InputFields'
import { DeployValues, deployAction } from '../app/queries'
import { validationSchema } from './FormValidationSchema'
import { useNavigate } from 'react-router-dom'


export const DeployForm = () => {
  const { showErrorSnackBar } = useSnackBar()
  const navigate = useNavigate()

  const mutation = useMutation(
    (values: DeployValues) => deployAction(values),
    {
      onMutate: (values: DeployValues) => {
        return values
      }
    }
  )
  React.useEffect(() => {
    if (mutation.isError) {
      showErrorSnackBar('Dobby request failed')
    }
  }, [mutation.isError])

  // TODO: Redirect to action details page
  if (mutation.isSuccess) {
    let actionId = mutation.data
    // Doing this because I was getting errors that it's possible that either of these values might be null
    // I don't think they ever will be though.
    let repoName = !!mutation.variables ? mutation.variables.repo_name :
                   !!mutation.context ? mutation.context.repo_name :
                   ''
    if (repoName == '') {
      showErrorSnackBar('Could not get repo name')
    }
    navigate(`../${repoName}/actions/${actionId}`)
  }

  return (
    <Formik
      initialValues={{
        repo_name: '',
        docker_image_uri: '',
        service_name: '',
        revision: -1,
        activate: false
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        mutation.mutate(values)
      }}>
      <Form id='deploy-action' autoComplete='false'>
        <DeployFocusView isLoading={mutation.isLoading} />
      </Form>
    </Formik>
  )
}

const DeployFocusView = ({ isLoading }: { isLoading: boolean }) => {
  const formik = useFormikContext()

  React.useEffect(() => {
    formik.setSubmitting(isLoading)
  }, [isLoading])

  return (
    <FocusView>
      <FocusHeader>
        <HeadingGroup>
          <Title>Make a new deployment</Title>
        </HeadingGroup>
        <FocusActions>
          <SubmitButton
            className='w-full sm:w-auto'
            form='deploy-action'
            isInProgress={isLoading}
          />
        </FocusActions>
        <FocusClose>
          {/* @ts-ignore */}
          <FocusCloseButton to={-1} />
        </FocusClose>
      </FocusHeader>
      <FocusBody>
        <div className='lg:max-w-3xl pb-2'>
          <ScrollPanel panelId='main'>
            <div className='space-y-4 md:space-y-6'>
              <RepoNameField />
              <DockerImageUriField />
              <ServiceNameField />
              <RevisionField />
            </div>
          </ScrollPanel>
        </div>
      </FocusBody>
    </FocusView>
  )
}
