import React from 'react'
import cx from 'classnames'
import {
  TextInputField,
  TextInputFieldProps
} from '@toasttab/buffet-pui-forms'

const BaseConfigField = (props: TextInputFieldProps) => (
  <TextInputField
    {...props}
    containerClassName={cx('md:w-1/2', props.containerClassName)}
  />
)

export const RepoNameField = () => (
  <BaseConfigField name='repo_name' label='Repository Name' placeholder='toast-drones' />
)

export const DockerImageUriField = () => (
  <BaseConfigField name='docker_image_uri' label='Docker Image Uri' placeholder='' />
)

export const ServiceNameField = () => (
  <BaseConfigField name='service_name' label='Service Name' placeholder='drones' />
)

export const RevisionField = () => (
  <BaseConfigField name='revision' label='Revision' placeholder='1' />
)
